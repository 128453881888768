import {Image, Button, Typography} from '../ui';
import err404 from '~/assets/images/Error404.png';

export function NotFound() {
  const heading = 'Error 404';
  const description = 'Sorry, this page doesn’t exist'; // There are 2 descriptions because the line break could not be made using "\n".

  return (
    <div className="px-nav text-center mt-10 mb-12 md:my-36">
      <Typography variant="headline1">{heading}</Typography>
      <Image
        src={err404}
        alt="Error 500"
        className="w-[234px] h-[161px] md:w-[570px] md:h-[402px] mx-auto my-5"
      />
      <Typography variant="body1" className="mb-5">
        {description}
      </Typography>
      <Button className="w-full h-12 md:w-auto" variant="cool" to={'/'}>
        Return to main page
      </Button>
    </div>
  );
}
